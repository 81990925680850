/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital@0;1&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main-color: #1bb095;
}

@font-face {
  font-family: 'utm-avo';
  src: local('utm-avo'), url(./fonts/utm-avo.ttf);
}
@font-face {
  font-family: 'utm-avobold';
  src: local('utm-avobold'), url(./fonts/utm-avobold.ttf);
}

@layer base {
  html {
    font-family: 'Montserrat', sans-serif;
    @apply text-[#1d1c4b] scroll-smooth;
  }

  /* hide placeholder on focus */
  input:focus::placeholder {
    color: transparent;
  }

  .font-outline-2 {
    text-shadow: -1px -1px 0 #ddd, 1px -1px 0 #ddd, -1px 1px 0 #ddd, 1px 1px 0 #ddd;
  }

  .scroller {
    overflow: scroll;
    overflow-x: hidden;
  }

  .scroller::-webkit-scrollbar {
    width: 1px;
  }

  .scroller::-webkit-scrollbar-track {
    background: #eee; /* color of the tracking area */
  }

  .scroller::-webkit-scrollbar-thumb {
    background-color: #ccc; /* color of the scroll thumb */
  }

  .scroller-x {
    overflow: scroll;
    overflow-y: hidden;
  }

  .scroller-x::-webkit-scrollbar {
    height: 1px;
  }

  .scroller-x::-webkit-scrollbar-track {
    /* background: #eee;  color of the tracking area */
    background: transparent; /* color of the tracking area */
  }

  .scroller-x::-webkit-scrollbar-thumb {
    background-color: #ccc; /* color of the scroll thumb */
  }

  select.no-arrow {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: none;
    padding-right: 0;
  }

  select.no-arrow::-ms-expand {
    display: none;
  }
}

@layer components {
  input {
    @apply border border-gray-200 p-3 rounded-xl text-gray-600 outline-1;
  }

  .primary-btn {
    @apply mx-auto active:shadow-none bg-deehiy font-semibold text-white text-xs xl:text-base items-center rounded-full px-3 sm:px-4 py-2 sm:py-2 shadow-md hover:shadow-lg hover:bg-opacity-90 duration-200;
  }

  .signup-btn {
    @apply mx-auto bg-deehiy text-white font-semibold rounded-full px-[30px] md:px-[40px] py-[8px] sm:py-3 text-[12px] md:text-[16px] shadow-md hover:shadow-lg hover:bg-opacity-90 duration-200;
  }

  .secondary-btn {
    @apply mx-auto text-deehiy font-semibold bg-white text-xs xl:text-base items-center border border-deehiy rounded-full px-2 sm:px-4 py-1 sm:py-2 hover:shadow-lg shadow-md hover:bg-opacity-90 duration-200;
  }

  .social-google-login-btn {
    @apply mx-auto bg-transparent border-border-gray text-black rounded-full text-[8px] md:text-[12px] px-[24px] md:px-[26px] py-1.5 sm:py-2.5 md:py-2.5 shadow-md hover:shadow-lg hover:bg-opacity-90 duration-200;
  }

  .social-facebook-login-btn {
    @apply mx-auto bg-transparent border-border-gray text-black text-[8px] md:text-[12px] rounded-full px-[18px] py-1.5 sm:py-2.5 md:py-2.5 shadow-md hover:shadow-lg hover:bg-opacity-90 duration-200;
  }

  .form-next-btn {
    @apply bg-deehiy flex whitespace-nowrap gap-2 font-bold text-base text-white 2xl:text-xl py-4 px-8 rounded-xl hover:shadow-lg shadow-md hover:bg-opacity-90 duration-200 ease-in-out;
  }

  .form-back-btn {
    @apply rounded-xl border-2 border-deehiy text-deehiy bg-white py-4 px-8 hover:shadow-lg shadow-md hover:bg-opacity-90 text-base font-bold 2xl:text-xl duration-200 ease-in-out;
  }

  .signup-auth-form {
    @apply flex mt-14 px-0 md:p-4 md:m-14 md:flex-row w-[100%] sm:w-[100%] md:w-[80%] xl:w-[67%] mx-auto md:border border-gray-200 bg-white rounded-xl md:shadow-xl;
  }

  .auth-form {
    @apply flex mt-12 md:mt-14 flex-col w-full sm:w-full md:w-1/2 xl:w-[500px] space-y-8 md:border border-gray-200 bg-white rounded-xl md:shadow-xl py-5 md:py-10 px-10 md:px-20 md:m-10 md:mx-auto;
  }

  .error-message {
    @apply bg-red-100 text-deehiy-red p-3;
  }

  .info-message {
    @apply bg-blue-100 text-blue-500 p-3;
  }

  .step-form-wrapper {
    @apply flex mx-auto space-y-5;
  }
  /* bg-blue-500 sm:bg-red-600 md:bg-yellow-300 lg:bg-orange-300 xl:bg-green-300 */

  .form-field-readOnly {
    @apply bg-gray-100 focus:ring-0 cursor-not-allowed border-gray-300 focus:border-gray-300;
  }
  .form-field {
    @apply focus:ring-deehiy border-gray-300 focus:border-deehiy;
  }

  .listing-title {
    @apply flex items-center h-16 sm:h-12 text-2xl sm:text-lg md:text-2xl font-bold line-clamp-2;
  }

  .listing-details-section {
    @apply border border-transparent border-b-deehiy py-6 sm:py-12;
  }

  .market-card {
    @apply xl:h-64 w-full xl:w-[280px] md:w-[260px] rounded-none sm:rounded-2xl shadow-md hover:shadow-xl cursor-pointer transition duration-500 ease-in transform sm:hover:scale-105 hover:z-0;
  }

  .width-overlay-layout {
    @apply w-full xl:w-[680px] lg:w-[500px] md:w-[479px];
  }

  .red-dot {
    @apply absolute  top-[10px] right-[15px] sm:top-[-5px] sm:right-[-5px] bg-red-500 text-white border border-white rounded-full px-[0.4em] py-[0.1em] text-xs font-bold;
  }

  .scrollbar-hide::-webkit-scrollbar {
    /* display: none; */
    background: #ddd;
    height: 6px;
  }
/* ******************************************************* */

.notifications-tray {
  position: relative;

}

.notifications-icon {
  cursor: pointer;
  position: relative;
}

/* .notifications-icon .red-dot {
  position: absolute;
  top: -5px;
  right: -5px;
  background: red;
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  padding: 0.1em 0.4em;
  font-size: 0.8em;
  font-weight: bold
} */

.tray-content {
  position: absolute;
  top: 45px;
  right: -100px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 360px;
  max-height: 67vh;
  overflow-y: auto;
  z-index: 10;
}

.tray-content h3 {
  padding: 10px;
  margin: 0;
  border-bottom: 1px solid #ddd;
  background: #f9f9f9;
}

.tray-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* ******************************************************* */
  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @layer utilities {
    .custom-scrollbar::-webkit-scrollbar {
      width: 15px;
      height: 20px;
      border: 1px solid black;
      border-radius: 100vh;
    }

    .custom-scrollbar::-webkit-scrollbar-track {
      border-radius: 100vh;
      background: #bbb;
      border: 1px solid black;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
      background: #28282b;
      border-radius: 100vh;
      border: 1px solid #28282b;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
      background: black;
    }
  }

  @responsive {
    .text-shadow {
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .text-shadow-md {
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
    }

    .text-shadow-lg {
      text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
    }

    .text-shadow-none {
      text-shadow: none;
    }
  }
}

body {
  /* background-image: url("../public/images/background.svg");
  background-repeat: no-repeat;
  background-size: 1200px;
  background-position: 83% 47%;
  font-family: 'Montserrat', sans-serif;
  */
  height: 100%;
  background: #f7f7f7;
}

.semibold {
  font-weight: 500; /* Adjust the font weight to simulate semibold */
}

/* Hamburger Menu */
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
  background: #000;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(0px);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translate(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}

/* multi range slider */

.slider {
  position: relative;
  width: 400px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #9fe5e1;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  visibility: hidden;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 400px;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* hide arrows in input(number) field */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.gridjs-container .gridjs-td {
  font-size: 1em;
  border: none;
}
.gridjs-container .gridjs-tr td {
  font-size: 1em;
  padding: 10px 0;
}
.gridjs-container .gridjs-th {
  font-size: 1.1em;
  color: #374151;
  text-align: center;
  border: none;
  background: transparent;
}

.center-text .gridjs-container .gridjs-table {
  text-align: center;
}

.gridjs-container .gridjs-tr tr:nth-child(even) {
  background: red !important;
}

.notification-size {
  /* height: 9.34vw; */
  width: 25vw;
}

.full-width {
  width: 100%;
}

.resizeable-img {
  /* width: 100%; */
  /* height: 100%; */
  object-fit: contain;
}

.feed-container {
  height: calc(100vh - theme('spacing.28'));
  background: yellow;
}

/* style quill */
.quill {
  /* otherwise emoji are covered when opened */
  /* display: flex !important;
  flex-direction: column-reverse !important; */
}

.quill-style ul {
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 35px;
}
.quill-style ol {
  padding-left: 35px;
  list-style-type: decimal;
  list-style-position: inside;
}
.quill-style ul ul,
ol ul {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 35px;
  padding-left: 15px;
}
.quill-style ol ol,
ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 35px;
  padding-left: 15px;
}

.square-link a {
  color: #1bb095 !important;
  text-decoration: underline !important;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  color: #bbb;
  font-style: normal !important;
}

.ql-container.ql-snow {
  font-family: 'Montserrat', sans-serif;
  font-size: inherit !important;
  border: none !important;
  height: 65% !important;
  /* background: yellow !important; */
}

.ql-toolbar.ql-snow {
  border: none !important;
  box-shadow: none !important;
  /* height: 40px !important; */
  /* background: pink !important; */
}

.custom-quill {
  color: inherit;
}

.mention {
  color: #1bb095 !important;
  font-weight: bold !important;
  background: #f2fdfb !important;
}

.ql-snow .ql-stroke {
  stroke: rgb(27 176 149) !important;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1;
  margin-left: 12px;
}

.quill > .ql-container > .ql-editor a {
  /* Your desired styles for URLs (color, underline, etc.) */
  color: #1bb095 !important;
  text-decoration: underline !important;
}

/* login input */

.login-input {
  border: none;
  border-bottom: 1px solid gray;
  border-radius: none;
  transition: border-color 0.3s;
  outline: none !important;
}

.login-input-manual-location {
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  transition: border-color 0.3s;
  outline: #1bb095;
}

.login-input-manual-location:focus {
  --tw-ring-shadow: none;
  border-color: #1bb095;
  border: solid 2px #1bb095;
}

.login-input-readOnly {
  border-color: #1bb095 !important;
  outline: none;
  color: #bbb;
  background: #efefef;
}

.login-input-error {
  border-color: red;
  outline: none;
}

.login-input:focus {
  --tw-ring-shadow: none;
  border-color: #1bb095;
}

.separator {
  position: relative;
  border: 0;
  height: 1px;
  background-color: #808080;
}

.overwrite-seperator {
  position: absolute;
  top: -0.8rem;
  left: 47%;
  display: inline-block;
  background: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.social-border {
  border: solid 1px #808080;
}

.btn-margin {
  margin-top: 1.2rem !important;
}

.signup-line {
  margin-top: 2rem;
}

.signup-overwrite-seperator {
  left: 46%;
}

/* .signup-social-btn {
  margin-top: 1rem;
} */

.img-container {
  position: relative !important;
}

.img-content-container {
  position: absolute;
  bottom: 1rem !important;
  margin-left: 1rem;
  margin-right: 1rem;
  color: white;
  background: #cccccc3b;
  box-shadow: 0px 20px 20px 20px #cccccc61;
}

.login-facebook-btn {
  margin-top: 1rem !important;
}

.loginLibBtn {
  margin-top: -1rem !important;
}

.registerPage {
  /* margin-top: 3rem !important; */
}

@media (max-width: 900px) {
  .notification-size {
    /* height: 9.34vw; */
    width: 40vw;
  }
}

@media (min-width: 600px) {
  /* flutter-view{
    top: 65px !important;    
  } */
  flutter-view .transparentTextEditing {
    box-shadow: none !important;
  }
}

@media (max-width: 600px) {
  .popup {
    position: fixed;
    /* top: 12rem; */
    top: 0;
    right: 0;
    height: 20vh;
    margin-top: 5rem;
  }

  .notification-size {
    width: 50vw;
  }
}

@media (max-width: 420px) {
  .notification-size {
    width: 60vw;
  }
}

.react-datepicker__day--selected {
  background-color: #1bb095 !important;
  font-weight: bold !important;
}

/* color picker */
.picker {
  position: relative;
}

.picker-swatch {
  width: 71px;
  /* width: auto; */
  height: 24px;
  border-radius: 4px;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.picker-popover {
  position: absolute;
  width: auto;
  background: #efefef;
  bottom: calc(100% + 8px);
  right: 0;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* animation */

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  60% {
    transform: scale(1.05) translateY(-20px); /* Adding bounce up */
    opacity: 1;
    box-shadow: 0 0 50px rgba(241, 241, 241, 0.5);
  }
  80% {
    transform: scale(0.98) translateY(10px); /* Bounce down */
  }
  100% {
    transform: scale(1) translateY(0px);
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.anim-pop {
  animation: anvil 0.6s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.Popover {
  z-index: 999;
  background-color: #1bb095;
  box-shadow: 0 10px 20px rgba(0, 10, 20, 0.4);
  color: white;
  font-size: 100%;
  padding: 24px 24px;
  border-radius: 15px;
  box-sizing: border-box;
  /* width: max-content; */
  width: 320px !important;
  max-width: calc(100vw - 10px);
}
